.catalogListItem {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  overflow: hidden;
}
.catalogListItem.isPromotion > a {
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.18);
}

.catalogCover {
  overflow: hidden;
  display: flex;
  position: relative;
  width: 100%;
  padding-top: 141.42%; /* ISO 216 paper size apect ratio, ie. A4 paper */
  margin-bottom: 0.75rem;
  border-radius: 0.1875rem;
}

.catalogCover > img {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
}

/* This positions the SVG icon from <UserContentImage /> correctly when there is no image URL */
.catalogCover > svg {
  position: absolute;
  top: calc(50% - 1rem);
  left: calc(50% - 1rem);
}

.catalogName {
  composes: ui14SemiBold from 'styles/typeography.css';
  color: var(--colorBlack2);
  margin: 0.75rem 0 0 0;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.sellerName {
  composes: ui12SemiBold from 'styles/typeography.css';
  color: var(--colorGray8);
  margin: 0 0 0.5rem 0;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.departmentsList {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
  line-height: 1.125rem;
}
.departmentListItem {
  display: inline;
  composes: ui12Regular from 'styles/typeography.css';
  color: var(--colorGray6);
  text-transform: capitalize;
}
.departmentListItem:not(:last-child):after {
  content: ', ';
}
.departmentListItem a {
  color: var(--colorGray6);
}

/* TODO: Find a solution to the specificity issue with CSS Modules below */
/*
 * retailer email type only:
 */
.isPreviewMobile .catalogCover {
  width: 280px !important;
  height: 407px !important;
}

.isPreviewMobile.catalogListItem > a {
  width: 280px !important;
}

.isPreviewDesktop .catalogCover {
  width: 249px !important;
  height: 361px !important;
}

.isPreviewDesktop.catalogListItem > a {
  width: 249px !important;
}

/* TODO: reenable this once the designer has added this to the design */
.catalogCurrencyContainer {
  display: none;
}
